<template>
<div class="">
    <headerComponent />
    <div class="container form-container information-container">
      <div class="text-center">
        <h3 class="pb-3">Hi {{data.supplier.businessName }}</h3>
        <h3>{{ data.repairer.businesssName }} <template v-if="data.repairer.suburb != ''">from {{ data.repairer.suburb }}</template> would like to invite you to quote for Parts pricing on a vehicle repairer they are quoting for below in <a href="https://partssearch.com.au"><span class="text-green">Parts</span>search.com.au</a></h3>
      </div>
    </div>
    <div class="container-main">
      <div class="d-flex flex-wrap justify-content-between pt-5">
          <div class="repairer-details">
            <h4>Repairers Details</h4>
            <table class="form-table">
              <tr>
                <td>Repairer Business Name :</td>
                <td>{{ data.repairer.businesssName }}</td>
              </tr>
              <tr>
                <td>Repairer ABN :</td>
                <td>{{ data.repairer.abn }}</td>
              </tr>
              <tr>
                <td>Repairer Estimate Nbr :</td>
                <td> {{ data.rfq.qpNumber }} </td>
              </tr>
              <tr>
                <td>Partssearch Ref Nbr :</td>
                <td> {{ data.rfq.number }} </td>
              </tr>
              <tr>
                <td>Suburb :</td>
                <td>{{ data.repairer.suburb }}</td>
              </tr>
            </table>
          </div>
          <div class="vehicle-details">
            <h4>Vehicle Details</h4>
            <table class="form-table">
              <tr>
                <td>Rego :</td>
                <td>{{ data.vehicle.rego }}</td>
              </tr>
              <tr>
                <td>Make :</td>
                <td>{{ data.vehicle.make }}</td>
              </tr>
              <tr>
                <td>Model :</td>
                <td>{{ data.vehicle.model }}</td>
              </tr>
              <tr>
                <td>VIN :</td>
                <td>{{ data.vehicle.vin }}</td>
              </tr>
              <tr>
                <td>Build Date :</td>
                <td>{{ data.vehicle.dom }}</td>
              </tr>
            </table>
          </div>
          <div class="supplier-business-details">
            <h4>Your Parts Supplier Business Details</h4>
            <table class="form-table">
              <tr>
                <td>Supplier Business Name :</td>
                <td>{{ data.supplier.businessName }}</td>
              </tr>
            </table>
          </div>
      </div>
      <div class="text-center pt-5 pb-5">
        <h3>Get Started</h3>
        <div class="btn d-flex justify-content-center mt-3">
          <b-button @click="createAccount" class="input-btn cancel mr-5">Create an Account</b-button>
          <b-button class="input-btn ml-5" @click="$router.push({name: 'continueAsAGuest'})">Continue as a Guest</b-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import headerComponent from "../../views/header-component.vue";
import Axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "getStartedForm",
  components: {
      headerComponent
  },
  mounted() {
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      data: 'baseData'
    })
  },
  methods: {
    createAccount() {
      window.open(process.env.VUE_APP_CREATE_ACCOUNT_URL, '_blank').focus()
    }
  }
}

</script>

<style scoped>
.form-container {
  padding-top: 190px;
}

.container-main {
  max-width: 1440px;
  padding-right: 80px;
  padding-left: 80px;
  margin: 0 auto;
}

.information-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 80px;
  padding-left: 80px;
}

.form-table {
  width: 100%;
}

.form-table td {
  border: none;
  padding: .5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #11141B;
}

.form-table tr td:first-child {
  width: 45%;
}

.repairer-details,
.supplier-business-details,
.vehicle-details {
  width: 30%;
  margin-right: 20px;
}

.text-green {
    color: #14B4BB;
}

a {
    text-decoration: none;
    color: #11141B;
}

a:hover {
  color: #11141B;
}

.form-table td:first-child {
  font-weight: bold;
  color: #a0a1a7;
}

.form-table td:last-child {
  font-weight: bold;
  color: #1c1f39;
}

.input-btn {
  width: auto;
  color: #FFFF;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.input-btn:hover {
  border-color: #29BBC1 !important;
}

.cancel {
  border-color: #29BBC1 !important;
}

@media screen and (max-width: 1000px) {
  .container-main div:first-child {
    flex-direction: column;
  }
  .repairer-details,
  .vehicle-details,
  .supplier-business-details {
    width: 100%;
    margin-top: 30px;
  }
  .form-table td {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    flex-direction: column;
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
}
</style>
