<template>
  <div class="quote-on-parts-pricing-guest-page">
    <header-component></header-component>
    <div>
      <div :class="{'hidden-block': isLoading}" class="container form-container mt-3">
        <div class="text-center mb-2-7">
          <h3 class="">Quote on Parts Pricing as a Guest</h3>
        </div>
        <div class="wizard__line"></div>
        <div class="d-flex tip-block">
          <div class="w-30">
            <h4>Tip: </h4>
            <p class="pr-5">Quote parts pricing in the same order as the Repairer to increase the chance of a quick
              Win/Order.</p>
          </div>
          <div @click="$refs['tablePricing'].scrollIntoView({ behavior: 'smooth' });" class="w-30 type-in-block"
               style="cursor: pointer;">
            <i
                class='pricing-icon bx bxs-keyboard '></i>
            <p class="tip-block-text">Type in Quote</p>
          </div>
          <div class="w-10 or-block">
            <p>OR</p>
          </div>
          <div class="w-30 drag-quote-block">
            <vue-dropzone v-on:vdropzone-file-added="addFileUpload"
                          v-on:vdropzone-success="successFileUpload"
                          v-on:vdropzone-complete="completeFileUpload"
                          v-on:vdropzone-sending="sendFileUpload"
                          v-on:vdropzone-error="errorFileUpload"
                          ref="fileUpDrop"
                          id="fileUpDrop"
                          class="dropify-wrapper dropzoneContainer drag-and-drop"
                          style="height: auto;"
                          :options="fileUploadDropzoneOptions"
                          :useCustomSlot="true">
              <div class="dropify-message">
                <div class="text-sm-center ex-image-upload-buttons">
                  <div class="">
                    <button :id="'clickBrowseFilesBtn'" style="border: none; background: none;">
                      <i class='pricing-icon bx bx-cloud-upload pr-5'></i>
                      <i class='pricing-icon bx bxs-file-pdf'></i>
                    </button>
                  </div>
                  <p class="tip-block-text">Drag & Drop PDF Quote - Auto Fill Pricing</p>
                  <!--                <button type="button" :id="'clickBrowseFilesBtn'"-->
                  <!--                        class="btn btn-primary waves-effect waves-light browse-btn">Browse-->
                  <!--                </button>-->
                  <!--                <button @click="clickCancelDropzoneFiles" type="button"-->
                  <!--                        class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel-->
                  <!--                </button>-->
                </div>
              </div>
            </vue-dropzone>

          </div>
        </div>
        <div class="wizard__line"></div>
      </div>
      <div :class="{'display-none': isLoading}"
           class="d-flex flex-wrap justify-content-between information-container pt-5 pr-3 pl-3">
        <div class="d-flex flex-wrap justify-content-between w-100 repairer-supplier-business-details">
          <div class="repairer-details">
            <h4>Repairers Details - Customer</h4>
            <table class="form-table">
              <tr>
                <td>Repairer Business Name:</td>
                <td>{{ data.repairer.businesssName }}</td>
              </tr>
              <tr>
                <td>Repairer ABN :</td>
                <td>{{ data.repairer.abn }}</td>
              </tr>
              <tr>
                <td>Repairer Estimate Nbr:</td>
                <td> {{ data.rfq.qpNumber }}</td>
              </tr>
              <tr>
                <td>Partssearch Ref Nbr:</td>
                <td> {{ data.rfq.number }}</td>
              </tr>
              <tr>
                <td>Suburb :</td>
                <td>{{ data.repairer.suburb }}</td>
              </tr>
            </table>
          </div>
          <div class="supplier-business-details">
            <h4>Supplier Details</h4>
            <table class="form-table">
              <tr>
                <td>Supplier Business Name :</td>
                <td>{{ data.supplier.name }}</td>
              </tr>
              <tr>
                <td>Registered Business Name:</td>
                <td>{{ data.supplier.businessName }}</td>
              </tr>
              <tr>
                <td>ABN Address:</td>
                <td>{{ data.supplier.abn }}</td>
              </tr>
              <tr>
                <td>Phone Nbr:</td>
                <td>{{ data.supplier.phone }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="d-flex flex-wrap w-100" style="justify-content: start">
          <div class="vehicle-details" style="margin-right: 30px;">
            <h4>Vehicle Details</h4>
            <table class="form-table">
              <tr>
                <td>Make:</td>
                <td>{{ data.vehicle.make }}</td>
              </tr>
              <tr>
                <td>Model:</td>
                <td>{{ data.vehicle.model }}</td>
              </tr>
            </table>
          </div>
          <div class="vehicle-details" style="margin-top: 55px;">
            <table class="form-table">
              <tr>
                <td>Rego:</td>
                <td>{{ data.vehicle.rego }}</td>
              </tr>
              <tr>
                <td>VIN:</td>
                <td>{{ data.vehicle.vin }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div :class="{'display-none': isLoading}" class="information-container pb-3 pl-3 pr-3" style="margin-top: 30px;">
        <div class="d-flex justify-content-between align-items-end">
          <h4 class="pb-2" style="width: 200px;">Quote Details</h4>
          <div class="d-flex w-30 align-items-center">
            <div class="vehicle-details-label">Quote Date</div>
            <div class="">
              <date-picker v-model="quoteDate"></date-picker>
            </div>
          </div>
          <div class="d-flex w-30">
            <div class="vehicle-details-label">Custom A/C</div>
            <div class="">
              <input
                  autocomplete="off"
                  type="text"
                  class="form-control quote-details-input"
                  v-model="quoteCustomAC"
              />
            </div>
          </div>
          <div class="d-flex w-30">
            <div class="vehicle-details-label">Salesman</div>
            <div class="">
              <input
                  autocomplete="off"
                  type="text"
                  class="form-control quote-details-input"
                  v-model="quoteSalesman"
              />
            </div>
          </div>
        </div>
      </div>
      <div :class="{'display-none': isLoading}" class="d-flex pb-5 table-container pl-3 pr-3"
           style="align-items: center; justify-content: center;">
        <table ref="tablePricing" class="part-table position-relative">
          <tr>
            <td class="head-td text-center">Part Number</td>
            <td class="head-td text-center col-description">Your Description</td>
            <td class="head-td text-center col-description customer-description-td">Customer Description</td>
            <td class="head-td text-center col-stock-status">Stock Status</td>
            <td class="head-td text-center col-qty">Qty</td>
            <td class="head-td text-center col-unit-list" v-if="isTypeOEM">Unit List</td>
            <td class="head-td text-center col-unit-net">Unit Net</td>
            <td class="check-td text-center">
              <label class="form-check-inline checkbox">
                <input
                    @change="checkAllParts"
                    v-model="isCheckAllParts"
                    class="form-check-input"
                    type="checkbox"
                    name="allCheckbox">
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </td>
          </tr>
          <div class="wizard__line position-absolute"></div>
          <tr class="part-tr" v-for="(prt, index) in quoteParts"
              @contextmenu.prevent.stop="openContext($event, prt, index)">
            <td>
              <b-form-input
                  :class="{'selected-item': prt.isSelected}"
                  :ref="'number-' + index"
                  class="td-input part-item"
                  @keydown.right="onKeyPressRight('number-' + index, $event)"
                  @keydown.left="onKeyPressLeft('number-' + index, $event)"
                  @keydown.down="onKeyPressDown('number-' + index, $event)"
                  @keydown.up="onKeyPressUp('number-' + index, $event)"
                  @change="updateRfq"
                  v-model="prt.number">
              </b-form-input>
            </td>
            <td>
              <b-form-input
                  :class="{'selected-item': prt.isSelected}"
                  :ref="'name-' + index"
                  @keydown.right="onKeyPressRight('name-' + index, $event)"
                  @keydown.left="onKeyPressLeft('name-' + index, $event)"
                  @keydown.down="onKeyPressDown('name-' + index, $event)"
                  @keydown.up="onKeyPressUp('name-' + index, $event)"
                  @change="updateRfq"
                  class="td-input part-item"
                  v-model="prt.name">
              </b-form-input>
            </td>
            <td :class="{'selected-item': prt.isSelected}" class="customer-description-td part-item"><p
                class="pl-2 mb-0">{{ prt.customerName }}</p></td>
            <td :class="{'selected-item': prt.isSelected}" class="part-item">
              <multiselect
                  :options="stockOptions"
                  v-model="prt.stock"
                  :ref="'stock-' + index"
                  @onKeyRight="onKeyPressRight('stock-' + index, $event)"
                  @onKeyLeft="onKeyPressLeft('stock-' + index, $event)"
                  track-by="value"
                  @select="updateRfq"
                  label="label"
                  :show-labels="false"
                  placeholder="Select stock">
              </multiselect>
            </td>
            <td class="qty-column">
              {{ prt.qty }}
              <!--                <number-formatter-->
              <!--                    :class="{'selected-item': prt.isSelected}"-->
              <!--                  type="text"-->
              <!--                  v-model="prt.qty"-->
              <!--                  :is-disabled="true"-->
              <!--                  class="form-control numeric td-numeric-input part-item"-->
              <!--                  format="0"></number-formatter>-->
            </td>
            <td v-if="isTypeOEM">
              <number-formatter
                  :is-disabled="prt.name === 'Not Applicable'"
                  :class="{'selected-item': prt.isSelected, 'hidden-input': prt.name === 'Not Applicable'}"
                  type="text"
                  :ref="'rrp-' + index"
                  @onKeyPressRight="onKeyPressRight('rrp-' + index, $event)"
                  @onKeyPressLeft="onKeyPressLeft('rrp-' + index, $event)"
                  @onKeyPressUp="onKeyPressUp('rrp-' + index, $event)"
                  @onKeyPressDown="onKeyPressDown('rrp-' + index, $event)"
                  @change="updateRfq"
                  v-model="prt.rrp"
                  class="form-control numeric td-numeric-input part-item"
                  format="$0,0.00"></number-formatter>
            </td>
            <td>
              <number-formatter
                  :is-disabled="prt.name === 'Not Applicable'"
                  :class="{'selected-item': prt.isSelected, 'hidden-input': prt.name === 'Not Applicable'}"
                  type="text"
                  :ref="'price-' + index"
                  @onKeyPressRight="onKeyPressRight('price-' + index, $event)"
                  @onKeyPressLeft="onKeyPressLeft('price-' + index, $event)"
                  @onKeyPressUp="onKeyPressUp('price-' + index, $event)"
                  @onKeyPressDown="onKeyPressDown('price-' + index, $event)"
                  @change="updateRfq"
                  v-model="prt.price"
                  class="form-control numeric td-numeric-input part-item"
                  format="$0,0.00"></number-formatter>
            </td>
            <td class="check-td part-item text-center" :class="{'selected-item': prt.isSelected}">
              <label class="form-check-inline checkbox">
                <input
                    v-model="prt.isChecked"
                    @change="checkedPart"
                    class="form-check-input"
                    type="checkbox"
                    name="allCheckbox">
                <span class="icon" :class="{'not-applicable': prt.name === 'Not Applicable'}"><i
                    class='bx bx-check'></i></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div :class="{'display-none': isLoading}" class="text-center pt-5 pb-5">
        <div class="btn d-flex justify-content-center mt-3">
          <b-button class="input-btn cancel mr-5" @click="$router.push({name: 'quoteOnPartsPricing'})">Back</b-button>
          <b-button @click="submitPricing" :disabled="!isCheckAllParts" class="input-btn ml-5">Submit Pricing</b-button>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="container pb-5 progress-block">
      <h3 class="text-center pb-3">Processing</h3>
      <div class="pdf-upload-loader-wrapper">
        <div class="pdf-upload-loader">
          <i class='bx bx-file'></i>
          <b-progress :value="valueLoader" :max="maxLoader" height="100%" :variant="variant"
                      class="pdf-upload-progress"></b-progress>
        </div>
      </div>
      <div class="btn d-flex justify-content-center mt-5 mb-5">
        <b-button class="input-btn cancel mr-5" @click="cancelImporting">Cancel</b-button>
      </div>
    </div>

    <div>
      <b-modal id="modal-submit" class="quote-on-parts-guest-modal-submit" size="lg" centered
               title="Submit Pricing as a Guest" header-class="modal-submit-header">
        <p class="my-4">We couldn't convert your Quote PDF this time. We will work on fixing this for next time. <b
            class="color-text-green">Support Team.</b></p>
        <p>Press <b>OK</b> to continue and - <b>Type in your Quote</b></p>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end mr-4">
            <b-button
                class="input-btn pr-5 pl-5"
                @click="$bvModal.hide('modal-submit')"
            >
              OK
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

    <VueContext
        ref="menuPart"
        class="context-menu-right-click"
        :closeOnClick="false"
        :closeOnScroll="false"
        :subMenuOffset="100"
        @close="closeContext"
    >
      <template slot-scope="item">
        <div v-if="item && item.data && item.data.part" @click="addPartAsNew(item.data.part, item.data.index)"
             class="context-item">Add as New Part
        </div>
        <div v-if="item && item.data && item.data.part && item.data.part.name !== 'Not Applicable'"
             @click="markPartNotApplicable(item.data.part)" class="context-item">Mark Not Applicable
        </div>
        <div v-if="item && item.data && item.data.part && item.data.part.name === 'Not Applicable'"
             @click="unMarkNotApplicable(item.data.part)" class="context-item">UnMark Not Applicable
        </div>
      </template>

    </VueContext>

  </div>
</template>

<script>
import headerComponent from '../../views/header-component.vue'
import datePicker from '../../components/datePicker'
import Axios from 'axios'
import vueDropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import NumberFormatter from '../../components/number-formatter'
import _ from 'lodash'
import { VueContext } from 'vue-context'

const rsMsMixin = {
  mounted () {
    const searchInput = this.$refs.search
    if (searchInput) {
      searchInput.addEventListener('keydown', this.handleKeydown)
    }
  },
  beforeDestroy () {
    const searchInput = this.$refs.search
    if (searchInput) {
      searchInput.removeEventListener('keydown', this.handleKeydown)
    }
  },
  methods: {
    handleKeydown (e) {
      if (e.keyCode === 37) {
        e.preventDefault()
        this.$emit('onKeyLeft')
        //   e.stopPropagation();

      } else if (e.keyCode === 39) {
        e.preventDefault()
        this.$emit('onKeyRight')
        //  e.stopPropagation();

      } else if (e.keyCode === 13) {
        var vm = this
        setTimeout(() => {
          vm.$emit('onKeyRight')
        }, 50)
      }
    }
  }
}

Multiselect.mixins.push(rsMsMixin)

export default {
  name: 'quoteOnPartsPricingAsAGuest',
  data: function () {
    return {
      quoteSalesman: '',
      quoteCustomAC: '',
      quoteDate: '',
      selectedPartIndex: null,
      stockOptions: [
        {
          label: 'None',
          textValue: 'None',
          value: 0
        },
        {
          label: 'In Stock',
          textValue: 'InStock',
          value: 1
        },
        {
          label: 'EX Stock',
          textValue: 'ExStock',
          value: 6
        },
        {
          label: 'Low Stock',
          textValue: 'LowStock',
          value: 2
        },
        {
          label: 'No Stock',
          textValue: 'NoStock',
          value: 3
        },
        {
          label: 'On Back Order',
          textValue: 'OnBackOrder',
          value: 4
        },
        {
          label: 'No Longer Avail',
          textValue: 'NoLongerAvail',
          value: 5
        }
      ],
      valueLoader: 0,
      maxLoader: 100,
      isLoading: false,
      isCancelImport: false,
      isLoadedParts: false,
      variant: '#29BBC1',
      interval: null,
      isCheckAllParts: false,
      fileUploadDropzoneOptions: {
        clickable: '#clickBrowseFilesBtn',
        url: appConfig.baseAPIURL + '/temp-access/scrape-pdf',
        headers: { 'Temp-Access': '46c7c043c9c989bd2445b3fd356f56c6' },
        method: 'post',
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'application/pdf, .doc, .docx',
        maxFilesize: 2,
        maxFiles: 2,
      },
      rfq: {},
      quoteParts: [],
    }
  },
  computed: {
    ...mapGetters({
      data: 'baseData'
    }),
    isTypeOEM () {
      return this.data.supplier.type === 'OEM'
    },
    computedAvailableRefs () {
      let result = []
      _.forEach(this.quoteParts, (part, index) => {
        let cells = []
        if (this.isTypeOEM) {
          cells = ['number-' + index, 'name-' + index, 'stock-' + index, 'rrp-' + index, 'price-' + index]
        } else {
          cells = ['number-' + index, 'name-' + index, 'stock-' + index, 'price-' + index]
        }

        result.push(cells)
      })
      return result
    },
  },
  components: {
    headerComponent,
    VueContext,
    datePicker,
    vueDropzone,
    NumberFormatter,
    Multiselect,
  },
  mounted () {
    this.loadInfo()
  },
  methods: {
    submitPricing () {
      let parts = _.map(this.quoteParts, (prt, index) => {
        let data = {
          qty: Number(prt.qty),
          stock: Number(prt.stock.value),
          rrp: Number(prt.rrp),
          price: Number(prt.price),
          partNumber: prt.number,
          name: prt.name,
        }
        if (prt.id) data.id = prt.id
        return data
      })

      let result = {
        parts: parts,
        details: {
          quoteDate: this.quoteDate,
          quoteCustomAC: this.quoteCustomAC,
          quoteSalesman: this.quoteSalesman,
        }
      }

      Axios.post('/temp-access/supplier/update-rfq', result).then((r) => {
        if (r?.data?._status) {
          this.$router.push({ name: 'theSuccess' })
        } else {
          this.$toast.error('Error')
        }
      })
    },
    checkAllParts () {
      _.forEach(this.quoteParts, (prt) => {
        prt.isChecked = this.isCheckAllParts
      })
    },
    checkedPart () {
      let isAllPartsChecked = true
      _.forEach(this.quoteParts, (prt) => {
        if (!prt.isChecked) {
          isAllPartsChecked = false
        }
      })
      this.isCheckAllParts = isAllPartsChecked
    },
    markPartNotApplicable (part) {
      part.lastName = part.name
      part.lastPrice = part.price
      part.lastRrp = part.rrp
      part.name = 'Not Applicable'
      part.price = null
      part.rrp = null
      this.$refs.menuPart.close()
    },
    unMarkNotApplicable (part) {
      part.name = part.lastName
      part.price = part.lastPrice
      part.rrp = part.lastRrp
      this.$refs.menuPart.close()
    },
    addPartAsNew (prt, index) {
      this.$refs.menuPart.close()
      let newPrt = Object.assign({}, prt)
      newPrt.customerName = newPrt.name
      newPrt.id = null
      let partsBeforeNewPart = this.quoteParts.slice()
      partsBeforeNewPart.splice(index, 0, newPrt)
      let originalParts = this.quoteParts
      _.forEach(partsBeforeNewPart, function (prt, indx) {
        if (indx > index && indx < originalParts.length) {
          prt.name = originalParts[indx].name
          prt.number = originalParts[indx].number
          prt.price = originalParts[indx].price
          prt.qty = originalParts[indx].qty
          prt.rrp = originalParts[indx].rrp
          prt.stock = originalParts[indx].stock
        } else if (indx >= originalParts.length) {
          prt.name = ''
          prt.number = null
          prt.price = null
          prt.qty = null
          prt.rrp = null
          prt.stock = null
        }
      })
      let partsAfterNewPart = []
      _.forEach(partsBeforeNewPart, function (prt) {
        if (!(prt.id == null && (prt.customerName === null || prt.customerName === '') && (prt.number === null || prt.number === '') && (prt.name === null || prt.name === ''))) {
          partsAfterNewPart.push(prt)
        }
      })
      this.quoteParts = partsAfterNewPart
    },
    openContext (e, part, index) {
      this.closeContext()
      // part.isSelected = true;
      this.selectedPartIndex = index
      this.$set(this.quoteParts[this.selectedPartIndex], 'isSelected', true)
      this.$refs.menuPart.open(e, { part: part, index: index })
    },
    closeContext () {
      if (this.selectedPartIndex === null) {
        return
      }
      this.$set(this.quoteParts[this.selectedPartIndex], 'isSelected', false)
      // this.quoteParts[this.selectedPartIndex].isSelected = false;
      this.selectedPartIndex = null
    },
    updateRfq () {
      // console.log('ldkfl;sdkfj')
      // NProgress.start();
      // // let data = [];
      // // _.forEach(this.quoteParts, (prt) => {
      // //   data.push({
      // //
      // //   })
      // // })
      // this.rfq.parts[0].rrp = 33
      // Axios.post('/temp-access/supplier/update-rfq', {
      //   parts: this.rfq.parts,
      // },)
      //     .then(() => {})
      // NProgress.done();
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    onKeyPressLeft (ref, e) {
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos > 0) {
          return
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentElement === 0) {
        let resultRow = []
        if (indexOfCurrentRow === 0) {
          resultRow = this.computedAvailableRefs[this.computedAvailableRefs.length - 1]
        } else {
          resultRow = this.computedAvailableRefs[indexOfCurrentRow - 1]
        }
        let resultRowLastIndex = resultRow.length - 1
        resultRef = resultRow[resultRowLastIndex]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement - 1]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      }
      if (resultRef.includes('stock')) {
        this.$refs[resultRef][0].$el.focus()
      } else {
        this.$refs[resultRef][0].focus()
      }
    },
    onKeyPressRight (ref, e) {
      console.log(ref, 'ref')
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos < elem.value.length) {
          return false
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null

      if (indexOfCurrentElement === this.computedAvailableRefs[indexOfCurrentRow].length - 1) {
        if (indexOfCurrentRow === this.computedAvailableRefs.length - 1) {
          resultRef = this.computedAvailableRefs[0][0]
        } else {
          resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][0]
        }
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement + 1]
        if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      }
      if (resultRef.includes('stock')) {
        this.$refs[resultRef][0].$el.focus()
      } else {
        this.$refs[resultRef][0].focus()
      }
    },
    onKeyPressUp (ref) {
      console.log('up', ref)
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow === 0) {
        resultRef = this.computedAvailableRefs[this.computedAvailableRefs.length - 1][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow - 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
        this.onKeyPressUp(resultRef)
        return
      }
      this.$refs[resultRef][0].focus()
    },
    onKeyPressDown (ref) {
      console.log('down')
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow + 1 === this.computedAvailableRefs.length) {
        resultRef = this.computedAvailableRefs[0][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef] || !this.$refs[resultRef][0] || this.$refs[resultRef][0].isDisabled || this.$refs[resultRef][0].disabled) {
        this.onKeyPressDown(resultRef)
        return
      }
      this.$refs[resultRef][0].focus()
    },
    startLoading () {
      this.isLoading = true
      this.isCancelImport = false
      this.interval = setInterval(() => {
        if (this.valueLoader === 100 && (this.isLoadedParts)) {
          setTimeout(() => {
            this.isLoading = false
            this.isLoadedParts = false
            this.valueLoader = 0
          }, 1000)
          clearInterval(this.interval)
          this.interval = null
        } else if (this.valueLoader !== 100) {
          this.valueLoader += 2
        }
      }, 100)
    },
    cancelImporting () {
      clearInterval(this.interval)
      this.interval = null
      this.isLoading = false
      this.isLoadedParts = false
      this.valueLoader = 0
      this.isCancelImport = true
    },
    errorFileUpload () {
      let interval = setInterval(() => {
        if (this.valueLoader === 100) {
          clearInterval(this.interval)
          this.interval = null
          this.isLoading = false
          this.isLoadedParts = false
          this.valueLoader = 0
          this.$bvModal.show('modal-submit')
          clearInterval(interval)
        }
      }, 500)
    },
    addFileUpload ($event) {
      this.startLoading()
      this.isCancelImport = false
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successFileUpload (file, response) {
      console.log(response, 'response')
      if (response.status || response._status) {
        this.isLoadedParts = true
        if (!this.isCancelImport) {
          let loadedParts = response.data.parts
          if (loadedParts.length > this.quoteParts.length) {
            let partsCopy = this.quoteParts.slice()
            this.quoteParts.splice(0, this.quoteParts.length)
            _.forEach(loadedParts, (prt, ind) => {
              this.quoteParts.push({
                id: partsCopy[ind] && partsCopy[ind].id ? partsCopy[ind].id : null,
                price: prt.price,
                rrp: prt.rrp,
                qty: prt.qty,
                stock: null,
                customerName: partsCopy[ind] && partsCopy[ind].customerName ? partsCopy[ind].customerName : '',
                name: prt.name,
                number: prt.number,
                isChecked: partsCopy[ind] && partsCopy[ind].isChecked ? partsCopy[ind].isChecked : false,
              })
            })
          } else {
            _.forEach(this.quoteParts, (prt, index) => {
              prt.id = prt.id ? prt.id : null
              prt.price = loadedParts[index].price
              prt.rrp = loadedParts[index].rrp
              prt.qty = loadedParts[index].qty
              prt.name = loadedParts[index].name
              prt.number = loadedParts[index].number
              isChecked: prt.isChecked ? prt.isChecked : false
            })
          }
        }

        let vm = this
        setTimeout(function () {
          vm.$refs.fileUpDrop.removeFile(file)
        }, 1000)
      } else {
        this.errorFileUpload()
      }
    },
    sendFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    clickCancelDropzoneFiles: function () {
      this.$refs.fileUpDrop.removeAllFiles(true)
      this.fileIds = []
    },
    loadInfo () {
      Axios.get('/temp-access/supplier/get-quote')
          .then((res) => {
            if (res && res.data.status) {
              this.rfq = res.data.rfq
              _.forEach(this.rfq.parts, (prt) => {
                this.quoteParts.push({
                  id: prt.id,
                  number: '',
                  customerName: prt.name,
                  name: '',
                  stock: '',
                  qty: prt.qty,
                  rrp: '',
                  isChecked: false,
                  price: '',
                })
              })
            }
          })
    },
  }
}
</script>

<style>
.context-menu-right-click {
  width: 163px !important;
}

.context-menu-right-click .context-item {
  padding: 15px 15px 11px 15px;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-weight: bold !important;
  text-align: left !important;
}

.context-menu-right-click .context-item:hover {
  background-color: #e9f8f9 !important;
  color: rgb(144, 191, 200) !important;
}

.part-table .form-control:disabled.hidden-input {
  opacity: 0 !important;
}

.part-item:focus {
  border: 2px solid #14b4bb !important;
}

.part-tr:hover .part-item {
  background-color: rgb(243, 243, 243) !important;
}

.form-control:disabled.selected-item {
  background-color: rgb(243, 243, 243) !important;
}

.selected-item {
  background-color: rgb(243, 243, 243) !important;
}

.td-input {
  border: none !important;
  font-size: 20px !important;
  color: #1C1F39 !important;
}

.progress-block {
  position: absolute !important;
  top: 301px;
  left: 341px;
}

.td-numeric-input {
  border: none !important;
  text-align: center;
  font-size: 20px !important;
  color: #1C1F39 !important;
}

.quote-on-parts-pricing-guest-page .multiselect .multiselect__tags {
  border: none !important;
  font-size: 18px;
}

.quote-on-parts-pricing-guest-page .multiselect__option {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #11141B;
}

.quote-on-parts-pricing-guest-page .multiselect__option--highlight {
  background: #f3f3f3;
  color: #11141B;
}

.quote-on-parts-pricing-guest-page .multiselect__single {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #11141B;
}

.quote-on-parts-pricing-guest-page label.checkbox > span.icon {
  height: 30px;
  width: 30px;
}

@media (max-width: 1666px) {
  .progress-block {
    left: 241px;
  }
}

@media (max-width: 1568px) {
  .progress-block {
    left: 141px;
  }
}

@media (max-width: 1427px) {
  .progress-block {
    left: 91px;
  }
}

@media (max-width: 1315px) {
  .progress-block {
    left: 0px;
  }
}

.hidden-block {
  opacity: 0;
}

.display-none {
  display: none;
}

.drag-and-drop {
  padding: 0px 55px !important;
  border: none !important;
}

.dropzone .dz-preview {
  display: none;
}

.vehicle-details-label {
  margin-right: 7px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}

.quote-details-input {
  width: 120px !important;
}

.wizard__line {
  width: 100%;
  height: 1px;
  border: 2px solid rgba(27, 30, 56, .12);
}

.w-30 {
  width: 30%;
}

.w-10 {
  width: 10%;
}

.type-in-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid rgba(27, 30, 56, .12);
  border-radius: 3px;
}

.drag-quote-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px dashed #14b4bb;
  stroke-dasharray: 66;
  border-radius: 3px;
}

.or-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**/
.tip-block {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pricing-icon {
  font-size: 50px;
  color: #2e6ca4;
}

.select-parts {
  border-spacing: 0.5em !important;
}

.table-line {
  height: 2px;
  background-color: #b3b3b3;
  position: absolute;
  width: calc(100vw - 300px);
}

.part-table {
  border-collapse: separate;
  border-spacing: 0.5em 1em !important;
  width: 100%;
}

.part-table td {
  border: 1px solid rgba(29, 29, 33, .74);
  text-align: left;
  vertical-align: middle;
  /*padding: 0.75rem 15px;*/
  height: 48px;
  line-height: 18px;
  font-size: 18px;
}

.part-table tr:first-child td {
  font-weight: 700;
}

.customer-description-td {
  border: 2px solid #14b4bb !important;
}

.customer-description-td p {
  line-height: 1 !important;
}

.check-td {
  border: none !important;
  width: 40px;
}

.tip-block-text {
  font-size: 15px;
  font-weight: bold;
  color: #a0a1a7;
}

.progress-bar {
  background-color: #29BBC1 !important;
}

.modal-submit-header {
  background-color: #11141B !important;
  color: white !important;
  padding: 30px !important;
}

.modal-body {
  padding: 30px !important;
}

.color-text-green {
  color: #29BBC1 !important;
}

.modal-title {
  font-size: 18px;
  font-weight: bold !important;
}

.modal-footer {
  border-top: none !important;
}

.close {
  color: white !important;
  font-size: 30px !important;
}
</style>

<style scoped>
.table-container {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}

.form-container {
  padding-top: 190px;
}

.form-table td {
  border: none;
  padding: .5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #11141B;
}

.form-table td:first-child {
  font-weight: bold;
  color: #a0a1a7;
}

.form-table td:last-child {
  font-weight: bold;
  color: #1c1f39;
}

.form-check-inline {
  margin-left: 1.75rem;
}

.theme-claimlinq label.checkbox > input[type="checkbox"]:checked + span.icon {
  background-color: #fff;
  color: #14b4bb;
  border: 3px solid #14b4bb;
}

.theme-claimlinq label.checkbox > input[type="checkbox"]:checked + span.icon i {
  color: #14b4bb;
  font-weight: bold;
}

.theme-claimlinq label.checkbox > input[type="checkbox"]:checked + span.icon.not-applicable {
  border: 3px solid #ffbe37;
}

.theme-claimlinq label.checkbox {
  margin-left: 10px;
  margin-right: 0;
}

.pdf-upload-progress-2 {
  width: 70%;
  height: 18px;
}

.pdf-upload-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.pdf-upload-loader {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pdf-upload-loader-wrapper {
  max-width: 600px;
  min-height: 20px;
  height: 20px;
  max-height: 20px;
  margin: 0 auto;
  position: relative;
  background-color: white;
}

input.form-control {
  height: 100% !important;
}

.col-stock-status {
  width: 192px;
  min-width: 100px;
}

.col-qty {
  min-width: 60px;
  width: 60px;
  max-width: 60px;
}

.col-description {
  min-width: 100px;
  max-width: 300px;
}

.col-unit-list,
.col-unit-net {
  width: 110px;
  min-width: 110px;
}

.repairer-details,
.supplier-business-details,
.vehicle-details {
  width: 40%;
}

.qty-column {
  text-align: center;
}

.input-btn.btn {
  padding: 0.75rem 39px;
}
</style>
