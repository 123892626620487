<template>
  <div class="">
    <headerComponent/>
    <div class="container form-container information-container">
      <div class="text-center">
        <h3 class="pb-3">Quote on Parts Pricing as a Guest</h3>
      </div>
    </div>

    <div>
      <h4 class="text-center information-container px-3" style="font-weight: normal;"><span>{{ company.name }}</span>, we
        need a few quick details to give to {{ data.repairer.businesssName }} your parts supply business details when he
        gets his pricing back for Vehicle VIN {{ data.vehicle.vin }} for his request on {{ data.rfq.number }}</h4>
      <div class="form-block mt-5">
        <div class="form-group">
          <label class="form-label">Registered Business Name</label>
          <!--          <input-->
          <!--              v-model="company.businessName"-->
          <!--              autocomplete="off"-->
          <!--              type="text"-->
          <!--              class="form-control input-field"-->
          <!--              name="businessname"-->
          <!--              placeholder="Registered Business Name"-->
          <!--          />-->
          <div class="form-group custom-multiselect">
            <multiselect
                :loading="isLoading"
                :custom-label="onlyName"
                :options="options"
                :showLabels="false"
                :max-height="203"
                :close-on-select="true"
                :internal-search="false"
                :searchable="true"
                :clear-on-select="false"
                :show-no-results="true"
                open-direction="bottom"
                v-model="computedBusinessName"
                @select="selectedCompany"
                placeholder="Search for your Business by Name or ABN"
                @search-change="companySearchChanges"
            >
              <template slot="noResult">
                We could not find and match a company based on that information. Please try again.
              </template>
            </multiselect>
          </div>
        </div>
        <div class="form-group">
          <label>Australian Business Number (ABN)</label>
          <input
              v-model="company.abn"
              autocomplete="off"
              type="text"
              class="form-control input-field"
              placeholder="Australian Business Number"
          />
        </div>
        <div class="form-group">
          <label>Business Address</label>
          <input
              v-model="company.address"
              autocomplete="off"
              type="text"
              class="form-control input-field"
              placeholder="Street Address"
          />
        </div>
        <div class="form-group">
          <label>Suburb</label>
          <input
              v-model="company.suburb"
              autocomplete="off"
              type="text"
              class="form-control input-field"
              placeholder="Suburb"
          />
        </div>
        <div class="form-group">
          <label>State</label>
          <multiselect
              :options="['NSW', 'VIC', 'QLD', 'SA', 'WA', 'NT', 'TAS']"
              v-model="company.state"
              placeholder="Select state">
          </multiselect>
        </div>
        <div class="form-group">
          <label>Postcode</label>
          <input
              v-model="company.postcode"
              autocomplete="off"
              type="text"
              class="form-control input-field"
              placeholder="Postcode"
          />
        </div>
        <div class="form-group">
          <label>Phone Nbr</label>
          <input
              v-model="company.phone"
              autocomplete="off"
              type="text"
              @keypress="isNumber($event)"
              class="form-control input-field"
              placeholder="Phone Number"
          />
        </div>
      </div>
    </div>

    <div class="btn-block pb-5">
      <div class="btn mt-3 pr-0">
        <b-button @click="$router.push({name: 'continueAsAGuest'})" class="input-btn cancel px-5">Back</b-button>
        <b-button @click="goNext" class="input-btn ml-5 px-5">Next</b-button>
      </div>
    </div>


  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import headerComponent from '../../views/header-component.vue'
import Axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quoteOnPartsPricing',
  data () {
    return {
      businessNameForSearch: {},
      lastTimeout: null,
      lastRequest: null,
      isLoading: false,
      options: [],
      company: {
        businessName: '',
        name: '',
        phone: '',
        abn: '',
        address: '',
        state: '',
        postcode: '',
        suburb: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      data: 'baseData',
    }),
    computedBusinessName:{
      get(){
        return this.businessNameForSearch
      },
      set(val){
        this.businessNameForSearch = val
      }
    }
  },
  methods: {
    ...mapActions({
      loadBaseData: 'loadBaseData'
    }),
    nameWithAbn ({ name }) {
      return name
    },
    onlyName({ onlyName }) {
      return onlyName
    },
    selectedCompany (value) {
      console.log(value, 'value')
      this.company.abn = value.abn
      this.company.businessName = value.onlyName
      this.getCompanyData(value.abn)
    },
    getCompanyData (abn) {
      Axios.post(
          '/ir/auth/get-company-data',
          {
            abn: abn,
          },
          {
            cancelToken: this.lastRequest.token,
          }
      ).then(res => {
        if (res.data.status) {
          //console.log(res.data.data)
          let data = res.data.data
          if (data) {
            if (data.mainBusinessPhysicalAddress.postcode) {
              this.company.postcode = data.mainBusinessPhysicalAddress.postcode
              if (data.mainBusinessPhysicalAddress.stateCode) {
                this.company.state = data.mainBusinessPhysicalAddress.stateCode
              }
            } else if (data.mainBusinessPhysicalAddress[0].postcode) {
              this.company.postcode = data.mainBusinessPhysicalAddress[0].postcode
              if (data.mainBusinessPhysicalAddress[0].stateCode) {
                this.company.state = data.mainBusinessPhysicalAddress[0].stateCode
              }
            }
           // this.company.businessName = data.mainName.organisationName;
            //console.log(data)
          }

        }
      })
    },
    goNext () {
      let data = {
        businessName: this.company.businessName,
        name: this.company.name,
        abn: this.company.abn,
        address: this.company.address,
        state: this.company.state,
        postcode: this.company.postcode,
        suburb: this.company.suburb,
        phone: this.company.phone,
      }
      Axios.post('/temp-access/update-temp-company', data)
          .then((res) => {
            if (res.data._status) {
              this.loadBaseData()
              this.$router.push({ name: 'quoteOnPartsPricingAsAGuest' })
            }
          })
    },
    isNumber (evt) {
      evt = (evt) ? evt : window.event
      let charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()

      } else {
        return true
      }
    },
    companySearchChanges (query) {
      if (query == '') {
        return
      }
      // console.log('KEYUP')
      this.options = []
      if (this.lastTimeout) {
        clearTimeout(this.lastTimeout)
        this.isLoading = false
      }
      if (this.lastRequest) {
        this.lastRequest.cancel()
        this.lastRequest = null
        this.isLoading = false
      }

      this.lastTimeout = setTimeout(() => {

        this.lastRequest = Axios.CancelToken.source()
        this.isLoading = true

        Axios.post(
            '/ir/auth/find-company',
            {
              companyName: query
            },
            {
              cancelToken: this.lastRequest.token
            }
        ).then(res => {
         // console.log(res.data)
          this.options = res.data
          this.isLoading = false
        }).catch(() => {
          //console.warn(err);
          this.isLoading = false
        })

      }, 300)

    }
  },
  mounted () {
    let interval = setInterval(() => {
      if (this.data.supplier.name) {
        this.company.name = this.data.supplier.name
        this.company.businessName = this.data.supplier.businessName
        this.company.abn = this.data.supplier.abn
        this.company.address = this.data.supplier.address
        this.company.state = this.data.supplier.state
        this.company.postcode = this.data.supplier.postcode
        this.company.suburb = this.data.supplier.suburb
        this.company.phone = this.data.supplier.phone
        this.businessNameForSearch = {onlyName:this.data.supplier.businessName};
        clearInterval(interval)
      }
    }, 10)
  },
  components: {
    Multiselect,
    headerComponent,
  },
}
</script>

<style scoped>
.form-container {
  padding-top: 190px;
}

.input-field,
.multiselect {
  width: 450px !important;
}

.btn-block {
  width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
}

.form-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
}

.multiselect .multiselect__tags {
  border: 1px solid rgba(27, 30, 56, 0.2) !important;
}

@media screen and (max-width: 420px) {
  .form-group {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .input-field {
    width: 100% !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
