<script>
/* eslint-disable  */
import numeral from 'numeral'

export default {
  name: 'number-formatter',
  data: function () {
    return {
      isFocus: false,
      oldValue: null,
      formattedValue: this.value,
    }
  },
  props: {
    refName: {
      type: String,
      default: ''
    },
    isTotalManual: {
      default: false,
    },
    originalValue: null,
    value: 0,
    percentage: {
      default: false
    },
    format: {
      type: String,
      default: '$0,0'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isCanBeZero: {
      type: Boolean,
      default: true,
    },
    isCanOnlyIncrease: {
      type: Boolean,
      default: false,
    },
    startValue: null,
  },
  mounted: function () {
    this.formatValue()
  },
  watch: {
    value: {
      handler: function (val, old) {
        console.log('watch.value', val, old)
        this.oldValue = old
        if (val != old) {
          if (!this.isFocus) {
            this.$nextTick(() => {
              this.formatValue()
            })
          }
        }
      }
    }
  },
  computed: {
    computedRef: function () {
      return 'numberFormatter' + this.refName
    },
  },
  methods: {
    onChange: function () {
      let time = 0
      if (this.isCanOnlyIncrease) {
        time = 200
      }

      setTimeout(() => {
        if (!this.isCanBeZero && Number(this.value) === 0 && Number(this.oldValue) !== 0) {
          this.$emit('changedValue', this.oldValue)
          return
        }
        if (this.isCanOnlyIncrease && Number(this.startValue) && Number(this.value) < Number(this.startValue)) {
          this.$emit('changedValue', this.startValue)
          // this.value = old;
          return
        }
        this.$emit('change')
      }, time)
    },
    updateValue: function (value) {
      console.log('number.update', value)
      if (this.isTotalManual && (value == '' || value === null)) {
        var result = null
      } else {
        var result = value == '0.0' ? '0.0' : value == '.' ? '0.' : numeral(value).format('0.[00]')
      }
      this.$emit('input', result)
    },
    formatValue: function () {
      if (this.percentage) {
        if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
          this.formattedValue = 100 + '%'
        } else {
          this.formattedValue = (numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) + '%'
        }
      } else {
        this.formattedValue = numeral(this.value).format(this.format)
        this.$emit('blur')
      }
    },
    selectAll: function (event) {
      this.$emit('focus')
      this.isFocus = true
      setTimeout(function () {
        event.target.select()
      }, 0)
    },

    setValue: function (val) {
      let result = numeral(val).format('0.[00]')
      let formattedResult = numeral(result).format(this.format)
      this.$emit('input', result)
      let vm = this
      setTimeout(function () {
        vm.$refs[this.computedRef].value = formattedResult
      }, 0)
    },
    emitEvent: function (type, event) {
      this.$emit(type, event)
    },
    focus: function () {
      this.isFocus = true
      this.$refs[this.computedRef].focus()
    },
    select: function () {
      this.isFocus = true
      this.$refs[this.computedRef].select()
    },
    click: function () {
      this.isFocus = true
      this.$refs[this.computedRef].click()
    },
    blur: function () {
      this.isFocus = false
      this.formatValue()
    }
  }
}

</script>

<template>
  <input :ref="computedRef"
         v-bind:value="formattedValue"
         v-on:keydown="emitEvent('onKeyDown', $event)"
         v-on:input="updateValue($event.target.value)"
         v-on:focus="selectAll"
         v-on:blur="blur"
         @keydown.left="$emit('onKeyPressLeft', $event)"
         @keydown.right="$emit('onKeyPressRight', $event)"
         @keydown.up="$emit('onKeyPressUp')"
         @keydown.down="$emit('onKeyPressDown')"
         :disabled="isDisabled"
         @change="onChange()">
</template>




