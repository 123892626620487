<template>
  <div class="">
    <header-component></header-component>

    <div class="container form-container mt-3">
      <div class="text-center mb-2-7">
        <h3 class="">Quote on Parts Pricing as a Guest</h3>
      </div>
      <div class="container mt-3">
        <h3 class="color-text-green text-center">Success</h3>
        <p class="pt-5 text-center"><b>Your parts pricing has now been submitted.</b></p>
        <p class="pt-5 text-center">Wanting your parts business to be used by more Repairers in Australia</p>
        <p class="text-center">Find out more and check us out at <a style="cursor:pointer;" @click="openPS"><span
            class="color-text-green">PARTS</span>SEARCH.com.au</a></p>
      </div>
    </div>

    <div class="text-center pt-5 pb-5">
      <div class="btn d-flex justify-content-center mt-3">
        <b-button @click="openPSInNewTab" class="input-btn mr-5">Find out More</b-button>
        <b-button @click="openPSInNewTab" class="input-btn">Sign Up Today</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import headerComponent from '../../views/header-component.vue'
import { appConfig } from '../../config'

export default {
  name: 'theSuccess',
  components: {
    headerComponent
  },
  methods: {
    openPS () {
      window.location.href = appConfig.homePageUrl
    },
    openPSInNewTab () {
      window.open(appConfig.homePageUrl, '_blank').focus()
    }
  }
}
</script>


<style scoped>
.form-container {
  padding-top: 190px;
}

.color-text-green {
  color: #29BBC1 !important;
}
</style>
