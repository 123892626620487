export const States = {
    data: {
        isLoaded: false,
        "supplier": {
            "businessName": "",
            "type": "",
            "abn": null,
            "address": null,
            "state": null,
            "suburb": null,
            "postcode": null
        },
        "vehicle": {
            "rego": "",
            "make": "",
            "model": "",
            "vin": "",
            "dom": ""
        },
        "repairer": {
            "businesssName": "",
            "abn": "",
            "suburb": "",
            "fullName": ""
        },
        "rfq": {
            "id": "",
            "number": "",
            "qpNumber": ""
        }
    }
}
