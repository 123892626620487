<script>
import { appConfig } from '../config'
export default {
  name: "header-component",
  methods: {
    openPS () {
      window.location.href =  appConfig.homePageUrl
    }
  }
}
</script>

<template>
    <div class="head-container">
        <div class="head-menu">
            <div class="container">
                <div class="head-menu-block">
                    <div class="logo">
                        <a class="desktop-logo" style="cursor: pointer" @click="openPS"><img src="../assets/logos/Group14.svg" alt="logo-repair-shop"></a>
                        <a class="mobile-logo" style="cursor: pointer" @click="openPS"><img src="../assets/logos/logo-small.svg" alt="logo-repair-shop"></a>
                    </div>
                    <span class="header-text">PARTSSEARCH - AUSTRALIA</span>
                </div>
            </div>
        </div>
        <div class="header-line"></div>
    </div>
</template>

<style scoped>
.head-container {
    max-width: 1440px;
}

.container {
    max-width: 1440px;
}

.head-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 111px;
    background-color: #11141B;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 100;
}

.head-menu-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-right: 80px;
    padding-left: 80px;
}

.mobile-logo {
    display: none;
}

.header-text {
    color: white;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.header-line {
    height: 50px;
    background-color: #29BBC1;
    position: fixed;
    width: 100vw;
    top: 110px;
    z-index: 100;
}

@media screen and (max-width: 768px) {
  .header-text {
    display: none;
  }
}
</style>
