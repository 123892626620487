/*eslint no-unused-vars: "off"*/

import Axios from "axios";

export const Actions = {
    loadBaseData(context) {
        return new Promise((resolve, reject) => {
            Axios.get('/temp-access/view-supplier-invite')
                .then((response) => {
                    if (response.data._status) {
                        context.commit('setData', response.data);
                        context.commit('setLoadingStatus', true)
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    context.commit('setLoadingStatus', true)
                    reject(error)
                })
        })
    }
};
