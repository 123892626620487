/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

import {States} from './states';
import {Getters} from './getters';
import {Mutations} from './mutations';
import {Actions} from './actions';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: States,
  getters: Getters,
  actions: Actions,
  mutations: Mutations,
  strict: process.env.NODE_ENV !== 'production'
})


export default store
