/*eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

import componentForHash from '@/forms/invite-supplier/component-for-hash'
import getStartedForm from '@/forms/invite-supplier/get-started-form'
import continueAsAGuest from '@/forms/invite-supplier/continue-as-guest'
import quoteOnPartsPricing from '@/forms/invite-supplier/quote-on-parts-pricing'
import quoteOnPartsPricingAsAGuest from '@/forms/invite-supplier/quote-on-parts-pricing-as-guest'
import theSuccess from '@/forms/invite-supplier/the-success'
import theError from '@/forms/invite-supplier/the-error'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'getStartedForm',
    component: getStartedForm,
  },
  {
    path: '/invite/:hash',
    name: 'componentForHash',
    component: componentForHash,
  },
  {
    path: '/invite-supplier/parts-pricing',
    name: 'quoteOnPartsPricing',
    component: quoteOnPartsPricing
  },
  {
    path: '/invite-supplier/continue-as-guest',
    name: 'continueAsAGuest',
    component: continueAsAGuest,
  },
  {
    path: '/invite-supplier/parts-pricing-as-guest',
    name: 'quoteOnPartsPricingAsAGuest',
    component: quoteOnPartsPricingAsAGuest,
  },
  {
    path: '/invite-supplier/success',
    name: 'theSuccess',
    component: theSuccess,
  },
  {
    path: '/error',
    name: 'theError',
    component: theError,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
