<template>
  <div class="">
    <div v-if="!isShow" class="load-bar">
      <div class="loader"></div>
      <h1>Loading...<br/>Please wait!</h1>
    </div>
    <div v-else class="wrapper theme-claimlinq is-visible">
      <div class="" style="background-color: #FFFFFF;">
        <!-- Content -->
        <div class="content-area">
          <div class="container-fluid">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    /* eslint-disable */
    import Vue from "vue";
    import VueRouter from 'vue-router';
    import accounting from "accounting";

    import Axios from "axios";
    import {mapActions, mapGetters} from "vuex";

    Vue.use(VueRouter);

    export default {
    name: "app",
    components: {},
    data: function () {
      return {
          isLoaded: false,
      };
    },
    created: function () {
      if (!Axios?.defaults?.headers?.common['Temp-Access'] && this.getIsAvailablePath(this.$route.path)) {
        let hash = localStorage.getItem('hash');
        if (hash) {
          Axios.defaults.headers.common['Temp-Access'] = hash;
          this.loadBaseData()
              .then((data) => {
                if (data._type !== 'active') {
                  this.showErrorPage('inactive');
                }
              })
              .catch(() => {
                this.showErrorPage();
              })
        } else if (!this.$route.path.includes('/invite/')) {
          this.showErrorPage();
        }
      }
    },
    watch: {},
    mounted() {
      this.$nextTick(() => {
        this.isLoaded = true;
      });
    },
      methods: {
        ...mapActions({
          loadBaseData: 'loadBaseData'
        }),
        getIsAvailablePath (path) {
          console.log(path, 'path')
          return path === '/' || path === '/invite-supplier/parts-pricing' || path === '/invite-supplier/continue-as-guest' || path === '/invite-supplier/parts-pricing-as-guest' || path === '/invite-supplier/success' || path === '/error'
        },
        showErrorPage (status = '') {
          this.$router.push({
            name: 'theError',
            params: {
              status: status
            },
          }).catch(err => err)
        }
      },
      computed: {
        ...mapGetters({
          data: 'baseData'
        }),
        isShow () {
          return this.isLoaded && (this.data.isLoaded || this.$route.path.includes('/invite/'))
        }
      }
  };
  Vue.filter("formatMoney", function (value) {
    return accounting.formatMoney(value);
  });
</script>

<style lang="scss">
  .wrapper {
    visibility: hidden;
    opacity: 0;
    transition: opacity 100ms ease-out;
    transition-delay: 200ms;
  }

  .wrapper.is-visible,
  .wrapper.theme-claimlinq,
  .wrapper.theme-supplier,
  .wrapper.theme-home {
    visibility: visible;
    opacity: 1;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2C3E50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2C3E50;

      &.router-link-exact-active {
        color: #42B983;
      }
    }
  }

  .b-form-btn-label-control.form-control > .btn:hover {
    color: green;
  }

  .b-form-btn-label-control.form-control > .form-control {
    padding-top: 9px;
  }

</style>

<style>
  .load-bar h1 {
    transform: translateX(0px);
  }
</style>
