<template>
  <div class="">
    <headerComponent />
    <div class="">
      <div class="text-center container form-container information-container">
        <h3 class="pb-3">Continue as Guest</h3>
        <p>Partssearch is a system that connect Repairers with Parts Suppliers. Get more ways to service your customers pricing needs, process direct orders, not just another pricing system ... your get better analytics of your customers and our marketplace activity.</p>
        <h3 class="mt-5">Find out more and check us out at <a href="https://partssearch.com.au"><span class="text-green">PARTS</span>SEARCH.com.au</a></h3>
      </div>

      <div class="btn d-flex justify-content-center mt-3">
        <b-button @click="viewPricing" сlass="input-btn mr-5" style="border-color: #29BBC1 !important; font-size: 18px;">View our Pricing</b-button>
        <b-button @click="$router.push({name: 'quoteOnPartsPricing'})" class="input-btn ml-5">Start Quoting on Parts Pricing</b-button>
      </div>
      <div :ref="'pricing'" class="pricing-container">
        <div class="table-pricing">
            <div class="table-block-first">
                <div class="table-col-functional">
                    <div class="table-row-first pricing-block-title">
                    </div>
                    <div class="functional-title">Unlimited User Accounts</div>
                    <div  class="functional-title">Quote / Invoice PDF File Storage
                        <span class="functional-text">(Unlimited)</span>
                    </div>
                    <div  class="functional-title">Repairer Quote Submission</div>
                    <div  class="functional-title">Part Order Management</div>
                    <div  class="functional-title">Part Order Credit Management</div>
                    <div  class="functional-title">Quote and Order performance Reporting</div>
                    <div  class="functional-title height-mobile">Import your pricing via XLS or CSV.
                        <span class="functional-text">(Import PDF copies. Subject to availability with your DMS provider)</span>
                    </div>
                    <div  class="functional-title">Customer Quote & Purchasing Analytics</div>
                    <div  class="functional-title">List your Parts supply Business to Auto collision Repairer</div>
                    <div  class="functional-title">Email & Online Support</div>
                    <div  class="functional-title height-mobile">Dealer Management System <br>Integration & transactions unlimited
                        <span class="functional-text">(DMS- Digital integration transactions. <br>Subject to availability with your DMS system provider)</span>
                    </div>
                    <div  class="functional-title height-mobile">Partssearch Points
                        <span class="functional-text">(Offer your customer Points on sales for use with Loyalty program partners)</span>
                    </div>
                </div>
            </div>
            <div class="slider">
                <div class="slider-list">
                    <div class="slider-track">
                        <div class="slide">
                            <div class="table-block">
                                <div class="table-col-functional-column">
                                    <div class="table-row-first"></div>
                                    <div class="functional-title">Unlimited User Accounts</div>
                                    <div class="functional-title">Quote / Invoice PDF File Storage
                                        <span class="functional-text">(Unlimited)</span>
                                    </div>
                                    <div  class="functional-title">Repairer Quote Submission</div>
                                    <div  class="functional-title">Part Order Management</div>
                                    <div  class="functional-title">Part Order Credit Management</div>
                                    <div  class="functional-title">Quote and Order performance Reporting</div>

                                    <div  class="functional-title height-mobile">Import your pricing via XLS or CSV.
                                        <span class="functional-text">(Import PDF copies. Subject to availability with your DMS provider)</span>
                                    </div>
                                    <div  class="functional-title">Customer Quote & Purchasing Analytics</div>
                                    <div  class="functional-title">List your Parts supply Business to Auto collision Repairer</div>
                                    <div  class="functional-title">Email & Online Support</div>
                                    <div  class="functional-title height-mobile">Dealer Management System <br>Integration & transactions unlimited
                                        <span class="functional-text">(DMS- Digital integration transactions. <br>Subject to availability with your DMS system provider)</span>
                                    </div>
                                    <div  class="functional-title height-mobile">Partssearch Points
                                        <span class="functional-text">(Offer your customer Points on sales for use with Loyalty program partners)</span>
                                    </div>
                                </div>
                                <div class="table-col-free">
                                    <div class="table-row-first">
                                        <div class="pricing-table-description">
                                            <p class="pricing-table-title">60 Day Trial</p>
                                            <p class="pricing-table-text">Get all features to service pricing requests for your customers</p>
                                            <p class="pricing-table-price">0%<span>/ Net Sales</span></p>
                                        </div>
                                        <div class="pricing-table-link pricing-table-link-active">
                                            <a style="cursor: pointer" @click="openPS">Select</a>
                                        </div>
                                    </div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="table-block">
                                <div class="table-col-functional-column">
                                    <div class="table-row-first"></div>
                                    <div class="functional-title">Unlimited User Accounts</div>
                                    <div  class="functional-title">Quote / Invoice PDF File Storage
                                        <span class="functional-text">(Unlimited)</span>
                                    </div>
                                    <div  class="functional-title">Repairer Quote Submission</div>
                                    <div  class="functional-title">Part Order Management</div>
                                    <div  class="functional-title">Part Order Credit Management</div>
                                    <div  class="functional-title">Quote and Order performance Reporting</div>
                                    <div  class="functional-title height-mobile">Import your pricing via XLS or CSV.
                                        <span class="functional-text">(Import PDF copies. Subject to availability with your DMS provider)</span>
                                    </div>
                                    <div  class="functional-title">Customer Quote & Purchasing Analytics</div>
                                    <div  class="functional-title">List your Parts supply Business to Auto collision Repairer</div>
                                    <div  class="functional-title">Email & Online Support</div>
                                    <div  class="functional-title height-mobile">Dealer Management System <br>Integration & transactions unlimited
                                        <span class="functional-text">(DMS- Digital integration transactions. <br>Subject to availability with your DMS system provider)</span>
                                    </div>
                                    <div  class="functional-title height-mobile">Partssearch Points
                                        <span class="functional-text">(Offer your customer Points on sales for use with Loyalty program partners)</span>
                                    </div>
                                </div>
                                <div class="table-col-level1">
                                    <div class="table-row-first">
                                        <div class="pricing-table-description">
                                            <p class="pricing-table-title">Standard</p>
                                            <p class="pricing-table-text">Low to Medium volume suppliers wanting to reach more customers</p>
                                            <p class="pricing-table-price">1.0%<span>/ Net Sales</span></p>
                                            <p class="pricing-table-price">$0<span>/ Month</span></p>
                                        </div>
                                        <div class="pricing-table-link">
                                            <a href="https://naevette-20738444.hubspotpagebuilder.com/partssearch-pricing-enquiry">Enquire</a>
                                        </div>
                                    </div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                    <div class="height-mobile"></div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="table-block">
                                <div class="table-col-functional-column">
                                    <div class="table-row-first"></div>
                                    <div class="functional-title">Unlimited User Accounts</div>
                                    <div  class="functional-title">Quote / Invoice PDF File Storage
                                        <span class="functional-text">(Unlimited)</span>
                                    </div>
                                    <div  class="functional-title">Repairer Quote Submission</div>
                                    <div  class="functional-title">Part Order Management</div>
                                    <div  class="functional-title">Part Order Credit Management</div>
                                    <div  class="functional-title">Quote and Order performance Reporting</div>
                                    <div  class="functional-title height-mobile">Import your pricing via XLS or CSV.
                                        <span class="functional-text">(Import PDF copies. Subject to availability with your DMS provider)</span>
                                    </div>
                                    <div  class="functional-title">Customer Quote & Purchasing Analytics</div>
                                    <div  class="functional-title">List your Parts supply Business to Auto collision Repairer</div>
                                    <div  class="functional-title">Email & Online Support</div>
                                    <div  class="functional-title height-mobile">Dealer Management System <br>Integration & transactions unlimited
                                        <span class="functional-text">(DMS- Digital integration transactions. <br>Subject to availability with your DMS system provider)</span>
                                    </div>
                                    <div  class="functional-title height-mobile">Partssearch Points
                                        <span class="functional-text">(Offer your customer Points on sales for use with Loyalty program partners)</span>
                                    </div>
                                </div>
                                <div class="table-col-level2">
                                    <div class="table-row-first">
                                        <div class="pricing-table-description">
                                            <p class="pricing-table-title">Premium</p>
                                            <p class="pricing-table-text">Medium to High Volume suppliers wanting analytics data for smart advert campaigning.</p>
                                            <p class="pricing-table-price">0.5%<span>/ Net Sales</span></p>
                                            <p class="pricing-table-price">$50<span>/ Month</span></p>
                                        </div>
                                        <div class="pricing-table-link">
                                            <a href="https://naevette-20738444.hubspotpagebuilder.com/partssearch-pricing-enquiry">Enquire</a>
                                        </div>
                                    </div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile-normal"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                    <div class="height-mobile"><img class="checkbox-checked" src="../../assets/img/Icon-checked.svg" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-arrows">
                    <div id="start-slide" class="slider-arrow slide-scroll-bar-active"></div>
                    <div id="middle-slide" class="slider-arrow"></div>
                    <div id="end-slide" class="slider-arrow"></div>
                </div>
            </div>
        </div>
      </div>
      <div class="mt-5 text-center">
        <h3>Continue as a Guest</h3>
        <b-button @click="$router.push({name: 'quoteOnPartsPricing'})" class="input-btn m-5">Start Quoting on Parts Pricing</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import headerComponent from "../../views/header-component.vue";

export default {
  name: "continueAsAGuest",
  components: {
      headerComponent
  },
  methods: {
    openPS () {
      window.open(process.env.VUE_APP_HomeBaseAPIURL + '/supplier/register', '_blank').focus()
    },
    viewPricing () {
      console.log(this.$refs['pricing'])
      this.$refs['pricing'].scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.form-container {
  padding-top: 190px;
}

* {
    margin: 0;
    padding: 0;
}

.container {
    width: 1440px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: #11141B;
}

hr {
    border: 1px solid rgba(17, 20, 27, 0.12);
}

.input-btn {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.input-btn:hover {
  border-color: #29BBC1 !important;
}

.cancel {
  border-color: #29BBC1 !important;
}

/* --MAIN FEATURES-- */

.pricing-container {
    max-width: 1440px;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.main-title {
    height: 369px;
    background: #F2F5F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 80px;
}

.main-title-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: #141F2E;
    width: 490px;
}

.main-text-small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #141F2E;
    width: 880px;
    margin-top: 16px;
}

.main-title-left {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 64px;
    text-align: center;
    color: #141F2E;
    text-align: start;
    width: 390px;
    margin: 72px 0 32px 80px;
}

.main-block {
    display: flex;
    margin-bottom: 59px;
}

.main-block-text {
    width: 470px;
}

.main-block-text h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #11141B;
}

.main-block-text p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #11141B;
}

.main-block-tabs {
    display: flex;
    flex-direction: column;
    margin-right: 91px;
}

.tabs {
    position: relative;
    display: flex;
    padding: 0 80px;
}

.pages-block {
    /* display: none; */
}

.main-content-active {
    display: block !important;
}

.pages-block-active {
    display: block !important;
}

.tabs-panel {
    display: none;
    background-color: #fff;
}

.tabs-btn-active {
    display: block;
    pointer-events: none;
    color: #14B4BB !important;
    border-bottom: 2px solid #14B4BB !important;
}

.tabs-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #11141B;
    border-bottom: 1px solid #D7D7D7;
    padding: 28px 32px;
    width: 234px;
    cursor: pointer;
}

.tabs-btn:hover {
    color: #14B4BB;
    border-bottom: 2px solid #14B4BB;
}

.main-register-block {
    background-repeat: no-repeat;
    background-size: cover;
    height: 286px;
}

.main-register-block-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 49, 56, 0.8);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.repairs-main-title,
.suppliers-main-title,
.pricing-main-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.repairs-main-title {
    max-width: 575px;
}

.suppliers-main-title {
    max-width: 1000px;
}

.registration-link {
    margin: 0 auto;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.28px;
    line-height: 24px;
    text-align: center;
    padding: 16px 32px;
    border-radius: 4px;
    background-color: #14B4BB;
}

.registration-link span {
    color: #FFFF;
    border-radius: 8px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

/* --MAIN PRICING-- */

.text-pricing {
    width: 960px;
}

.text-pricing-small {
    width: 650px;
}

.table-pricing {
    display: flex;
    justify-content: space-between;
    margin: 100px 0 100px 0;
}

.table-pricing .table-block-first {
    display: flex;
}

.table-block-first {
    width: 32%;
}

.pricing-block-title-mobile {
    display: none;
}

.pricing-block-title h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 64px;
    color: #141F2E;
    display: block !important;
    padding-top: 25px;
}

.pricing-block-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #14B4BB;
    padding-top: 12px;
}

.table-row-first {
    height: 450px !important;
    align-items: flex-start !important;
}

.table-col-free {
    border: 1px solid #14B4BB;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    border-top: 8px solid #14B4BB;
}

.table-col-functional {
    text-align: left;
    color: #212524;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 24px;
}

.table-col-functional div,
.table-col-functional-column div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 88px;
    padding-right: 27px;
}

.functional-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #11141B;
    max-width: 370px;
}

.functional-text {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(17, 20, 27, 0.5);
    max-width: 370px;
}

.table-col-free div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 86px;
    border-bottom: 1px solid #E5EAF4;
    margin: 0 20px;
}

.table-col-free div:first-child {
    justify-content: normal;
    align-items: baseline;
}

.table-col-level1 .table-row-first,
.table-col-level2 .table-row-first {
    padding-top: 8px;
}

.table-col-functional {
    border: 1px solid #FFF;
    border-radius: 8px;
}

.table-col-level1,
.table-col-level2 {
    border: 1px solid #E5EAF4;
    border-radius: 8px;
}

.table-col-level1 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 86px;
    border-bottom: 1px solid #E5EAF4;
    margin: 0 20px;
}

.table-col-level2 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 86px;
    border-bottom: 2px solid #D8DDE8;
    margin: 0 20px;
}

.table-row-first {
    border-bottom: 1px solid white !important;
    justify-content: space-between !important;
}

.pricing-table-description {
    margin: 0 !important;
    border: none !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
}

.pricing-table-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #11141B;
    margin-top: 32px;
}

.pricing-table-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #11141B;
    margin-top: 12px;
}

.pricing-table-text-memo {
    margin-top: 10px;
    color: #111324;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.pricing-table-price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #36B37E;
    margin-top: 16px;
}

.pricing-table-price span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5A7184;
    margin-left: 12px;
}

.pricing-table-link {
    width: 100%;
    padding-left: 0 !important;
    border-bottom: none !important;
    margin: 0 !important;
}

.table-row-last {
    border-bottom: none !important;
}

.pricing-table-link a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #14B4BB;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #14B4BB;
}

.pricing-table-link-active a {
    color: #FFFFFF;
    background: #14B4BB;
    border: 1px solid #14B4BB;
}

.pricing-table-link:hover a {
    color: #FFFFFF;
    background: #14B4BB;
}

.checkbox-checked {
    height: 20px;
    width: 20px;
}

.table-element-large {
    height: 100px !important;
}

.table-addons {
    display: flex;
    margin: 42px 0;
}

.addons-col-functional {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    text-align: left;
    color: #212524;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 24px;
}

.table-col-functional-column {
    display: none;
}

.addons-col-price {
    width: 75%;
    display: flex;
    flex-direction: column;
    color: #00B050;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 27px;
}

.addons-col-functional div,
.addons-col-price div {
    display: flex;
    align-items: center;
    height: 84px;
    border-bottom: 1px solid #E9E9E9;
    padding-right: 27px;
}

.addons-col-price div {
    padding: 0 27px;
}

.addons-col-price div:hover {
    background: #F7F7FF;
}

.addons-table {
    margin: 42px 0;
    border-collapse: collapse;
    overflow: hidden;
}

.addons-table tbody tr th {
    width: 25%;
    text-align: left;
    color: #212524;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 24px;
    padding: 9px 27px 20px 0;
}

.addons-table tbody td {
    color: #00B050;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 27px;
    padding: 9px 27px 20px 27px;
}

.addons-table tbody tr {
    box-sizing: border-box;
    height: 1px;
    width: 1295px;
    border-bottom: 1px solid #E9E9E9;
}

.addons-table-active:hover {
    background: #F7F7FF;
}

.changeBtn {
    background-color: #111324 !important;
}

.wrapper {
    padding: 0 80px;
}

.pricing-block-title {
    justify-content: flex-start !important;
}

.slider {
    width: 68%;
}

.slider-track {
    display: flex;
    justify-content: space-between;
}

.slide {
    width: 30%;
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 1280px) {
    .container {
        width: 100%;
        margin: 0;
    }

    .head-box {
        height: 507px;
    }

    .desktop-logo {
        display: none;
    }

    .mobile-logo {
        display: block;
    }

    .head-block {
        width: 325px;
    }

    .head-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
    }

    .head-image {
        width: 45%;
    }

    .text-pricing {
        width: 100%;
    }

    .tabs {
        flex-wrap: wrap;
    }

    .main-block-tabs-content {
        margin-top: 25px;
    }

    .main-title {
        position: relative;
        z-index: 99;
    }

    .main-block-tabs {
        margin-right: 20px;
    }

    .main-block {
        flex-direction: column;
    }

    .main-block img {
        margin-top: 25px;
    }

    .main-text-small {
        width: auto;
    }

    .table-row-first {
        height: 510px !important;
    }

    .footer-container {
        flex-direction: column;
    }

    .footer-block-right {
        width: 100%;
        justify-content: flex-start;
    }

    .copyright-text {
        margin: 25px 0;
    }
}

@media screen and (max-width: 1280px) and (min-width: 768px) {
    .pricing-table-price span {
        display: block;
    }

    .table-row-first {
        height: 580px !important;
    }
}

@media screen and (max-width: 1100px) {
    .hamburger-menu {
        display: flex;
    }

    #menu__toggle:checked~.menu__btn .bx-menu {
        display: none;
        transform: translate(-100%, -100%);
    }

    #menu__toggle:checked~.menu__btn .bx-x-circle {
        display: block;
    }

    #menu__toggle:checked~.menu__box {
        visibility: visible;
        right: 0;
    }

    #menu__toggle {
        opacity: 0;
    }

    .developers-menu__btn {
        width: 100%;
        padding: 32px 0;
    }

    .hamburger-item-developers {
        padding: 0 !important;
        align-items: center;
    }

    #developers-menu__toggle:checked~.developers-menu__btn .bx-menu {
        display: none;
        transform: translate(-100%, -100%);
    }

    #developers-menu__toggle:checked~.menu__btn .bx-x-circle {
        display: block;
    }

    #developers-menu__toggle:checked~.hamburger-developer-menu {
        visibility: visible;
        opacity: 1;
        background-color: #11141B;
    }

    #developers-menu__toggle:checked~.developers-menu__btn,
    #developers-menu__toggle:checked~.bx-chevron-up {
        color: #14B4BB;
    }

    #developers-menu__toggle:checked~.bx-chevron-down {
        display: none;
    }

    #developers-menu__toggle:checked~.bx-chevron-up {
        display: block;
    }

    .green-item {
        color: #14B4BB !important;
    }

    #developers-menu__toggle {
        opacity: 0;
        display: none;
    }

    .menu__btn {
        display: flex;
        align-items: center;
        position: fixed;
        top: 35px;
        right: 20px;
        cursor: pointer;
        z-index: 1;
    }

    .bx-menu::after {
        display: none;
    }

    .hamburger-developer-menu {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        width: calc(100% - 40px);
        position: absolute;
        top: 260px;
    }

    .hamburger-developer-menu li {
        border-bottom: none !important;
        padding: 0 0 32px 0 !important;
    }

    .hamburger-developer-menu li:last-child {
        border-bottom: 1px solid #FFFFFF !important;
    }

    .menu__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: fixed;
        visibility: hidden;
        top: 111px;
        right: -100%;
        width: 100%;
        height: calc(100vh - 111px);
        list-style: none;
        text-align: center;
        background: #11141B;
    }

    .hamburger-logo {
        position: absolute;
        left: 20px;
        top: 35px;
    }

    .menu__box li {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #FFFFFF;
    }

    .hamburger-menu-block {
        width: calc(100% - 40px);
        padding: 0 20px;
    }

    .hamburger-menu-block:last-child {
        padding-bottom: 150px;
        display: flex;
        flex-direction: column;
    }

    .hamburger-menu-block:last-child li {
        justify-content: center;
        border: none;
    }

    .hamburger-menu-block:last-child li .register-btn {
        padding: 0;
    }

    .hamburger-item {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        width: 100%;
        text-align: left;
        padding: 32px 0;
        justify-content: space-between;
    }

    .hamburger-developer-menu .hamburger-item {
        padding: 0 !important;
    }

    .register-btn,
    .login-down {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-top: 32px;
        width: 100%;
    }

    .menu__box,
    .hamburger-developer-menu {
        transition-duration: .25s;
    }

    .menu__item {
        transition-duration: .25s;
    }

    .desktop-menu {
        display: none;
    }

    .bx-menu,
    .bx-x-circle {
        color: #FFF;
        font-size: 35px;
    }

    .bx-x-circle {
        display: none;
    }

    .functional-title {
        height: 130px !important;
    }

    .height-mobile {
        height: 200px !important;
    }

    .height-mobile-normal {
        height: 129px !important;
    }

    .table-col-level2 .height-mobile {
        height: 199px !important;
    }

    .table-col-level2 .height-mobile-normal {
        height: 128px !important;
    }
}

@media screen and (min-width: 768px) {
    .slider-track {
        transform: none !important;
    }
}

@media screen and (max-width: 768px) {
    .head-box {
        align-items: flex-start;
        position: relative;
    }

    .head-block {
        width: 325px;
        padding-top: 32px;
    }

    .title-container {
        padding: 0 20px;
    }

    .head-title-bottom {
        margin-top: 8px;
    }

    .head-image {
        width: 324px;
        right: 0;
        bottom: -51px;
        z-index: 1;
        top: auto;
    }

    .main-title {
        position: relative;
        z-index: 99;
        align-items: flex-start;
    }

    .head-menu-block {
        padding: 0 20px;
    }

    .main-title {
        padding: 0 20px;
    }

    .main-title-text,
    .main-text-small {
        width: auto;
        text-align: left;
    }

    .main-title-text {
        padding-top: 32px;
        font-size: 24px;
        line-height: 36px;
    }

    .main-text-small {
        padding-bottom: 32px;
        font-size: 16px;
        line-height: 26px;
    }

    .tabs,
    .main-block,
    .footer-container {
        flex-direction: column;
    }

    .footer-block-right {
        justify-content: normal;
    }

    .main-title-left {
        margin: 32px 20px;
        font-size: 24px;
        line-height: 36px;
        width: auto;
    }

    .tabs {
        padding: 0 20px;
    }

    .tabs-btn {
        font-size: 18px;
        line-height: 20px;
    }

    .main-block {
        padding-top: 45px;
        margin-bottom: 10px;
    }

    .main-block-text {
        width: 100%;
    }

    .main-block-text h4 {
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 32px;
    }

    .main-block-text p {
        padding-bottom: 24px;
        font-size: 16px;
        line-height: 30px;
    }

    .main-block img {
        width: 95%;
    }

    .repairs-main-title,
    .suppliers-main-title,
    .pricing-main-title {
        padding: 0 20px;
        font-size: 24px;
        line-height: 29px;
        max-width: 100%;
    }

    .footer-container {
        padding: 0 20px;
        margin-bottom: 0;
    }

    .footer-block-left,
    .footer-block-about {
        width: 100%;
    }

    .footer-block h4 {
        font-size: 20px;
        line-height: 25px;
    }

    .about-block-text {
        font-size: 16px;
        line-height: 30px;
    }

    .wrapper {
        padding: 0 20px;
    }

    .footer-block-right {
        flex-direction: column;
    }

    .footer-block-navigation {
        width: 100%;
    }

    .footer-block-navigation {
        margin-bottom: 40px;
    }

    .copyright-text {
        margin: 25px 0;
    }

    .slider {
        position: relative;
        user-select: none;
        touch-action: pan-y;
        width: 100%;
    }

    .table-pricing {
        margin-top: 30px;
    }

    .slider img {
        poiner-events: none;
    }

    .slider-list {
        overflow: hidden;
    }

    .slider-list.grab {
        cursor: grab;
    }

    .slider-list.grabbing {
        cursor: grabbing;
    }

    .slider-track {
        display: flex;
    }

    .slide {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .slider-arrows {
        margin-top: 15px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        height: 8px;
        background: #E5EBEF;
        border-radius: 10px;
        position: sticky;
        bottom: 0;
        z-index: 1;
    }

    .slider-arrow {
        width: 33%;
        background: #14B4BB;
        border-radius: 10px;
        opacity: 0;
    }

    .slide-scroll-bar-active {
        opacity: 1;
    }



    .table-block-first {
        display: none !important;
    }

    .table-col-functional-column {
        display: block;
        width: 45%;
    }

    .table-col-functional-column {
        margin-left: 2px;
    }

    .table-col-free,
    .table-col-level1,
    .table-col-level2 {
        width: 55%;
    }

    .table-block {
        display: flex;
    }

    .next,
    .prev {
        background: none;
        border: none;
        margin: 0 10px;
        font-size: 30px;
        cursor: pointer;
    }

    .next.disabled,
    .prev.disabled {
        opacity: .25;
        pointer-events: none;
    }

    .pricing-table-title {
        font-size: 20px;
        line-height: 24px;
    }

    .pricing-table-text {
        font-size: 14px;
        line-height: 24px;
    }

    .pricing-table-price {
        font-size: 24px;
        line-height: 29px;
    }

    .pricing-table-price span {
        font-size: 16px;
        line-height: 19px;
    }

    .pricing-table-link a {
        font-size: 16px;
        line-height: 19px;
    }

    .functional-title {
        font-size: 14px;
        line-height: 20px;
    }

    .functional-text {
        font-size: 14px;
        line-height: 18px;
    }

    .pricing-block-title-mobile {
        display: block;
    }

    .pricing-block-title-mobile h2 {
        font-size: 24px !important;
        line-height: 36px !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        color: #141F2E;
        padding-top: 25px;
    }

    .pricing-block-text {
        font-size: 16px !important;
        line-height: 19px !important;
    }

    .height-mobile {
        height: 150px !important;
    }

    .table-col-level2 .height-mobile {
        height: 149px !important;
    }

    .table-col-functional div,
    .table-col-functional-column div {
        padding-right: 14px;
    }

    .table-col-free {
        box-shadow: none;
    }
}

@media screen and (max-width: 480px) {
    .functional-title {
        height: 120px !important;
    }

    .height-mobile-normal {
        height: 119px !important;
    }

    .table-col-level2 .height-mobile-normal {
        height: 118px !important;
    }

    .height-mobile {
        height: 220px !important;
    }

    .table-col-level2 .height-mobile {
        height: 219px !important;
    }
}

@media screen and (max-width: 365px) {
    .head-image {
        display: none;
    }

    .head-block {
        width: 100%;
    }
}

@media screen and (max-width: 345px) {
    .head-block {
        width: auto;
    }

    .suppliers-main-title {
        display: none;
    }
}
</style>
