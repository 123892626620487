<template>
  <div class="load-bar">
    <div class="loader"></div>
    <h1>Loading...<br/>Please wait!</h1>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Axios from "axios";

export default {
  name: "componentForHash",
  mounted () {
    localStorage.setItem('hash', this.$route.params.hash);
    if (this.$route?.params?.hash) {
      Axios.defaults.headers.common['Temp-Access'] = this.$route.params.hash;
      this.loadBaseData().then((data) => {
        if (data._type === 'active') {
          this.$router.push({
            name: 'getStartedForm'
          })
        } else {
          this.showErrorPage('inactive');
        }
      })
      .catch(() => {
        this.showErrorPage();
      })
    } else {
      this.showErrorPage();
    }
  },
  methods: {
    ...mapActions({
      loadBaseData: 'loadBaseData'
    }),
    showErrorPage (status = '') {
      this.$router.push({
        name: 'theError',
        params: {
          status: status
        },
      }).catch(err => err)
    }
  }
}
</script>

<style scoped>

</style>
