<template>
  <div class="">
    <header-component></header-component>

    <div class="container form-container mt-3">
<!--      <div class="text-center mb-2-7">-->
<!--        <h3 class="">Quote on Parts Pricing as a Guest</h3>-->
<!--      </div>-->
      <div class="container mt-3">
        <h3  v-if="$route && $route.params && $route.params.status === 'inactive'" class="color-text-red text-center">This invite isn't active.</h3>
        <h3 v-else class="color-text-red text-center">Invite not found</h3>
<!--        <p v-if="$route && $route.params && $route.params.status === 'inactive'" class="pt-5 text-center">This invite is not active.</p>-->
        <!--        <p v-else class="pt-5 text-center"><b>Your parts pricing has not been submitted.</b></p>-->
        <p class="pt-5 text-center">Please close this window </p>
      </div>
    </div>
  </div>
</template>

<script>
import headerComponent from "../../views/header-component.vue";

export default {
  name: "theError",
  components: {
    headerComponent
  },
}
</script>



<style scoped>
.form-container {
  padding-top: 190px;
}

.color-text-red {
  color: red !important;
}
</style>
