<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import VCalandarDatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'DatePicker',
  props: {
    placeHolderText: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      masks: {
        input: 'DD/MM/YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'DD/MM/YYYY'
      },
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (val) {
        return val
      }
    }
  },
  methods: {
    updateData (val) {
      this.$emit('input', val)
      this.$emit('onChange')
    }
  },
  components: {
    VCalandarDatePicker,
  },
}

</script>

<template>
  <v-calandar-date-picker v-model="computedValue"
                          color="teal"
                          :attributes="[{key: 'today',highlight: true,dates: new Date(),},]"
                          :masks="masks"
                          :model-config="modelConfig"
                          @input="updateData"
                          :popover="{ visibility: 'click' }">
    <template v-slot="{ inputValue, inputEvents }">
      <input
          class="form-control"
          style="width: 120px"
          :value="inputValue"
          v-on="inputEvents"
          :disabled="isDisabled"
          :readonly="isReadonly"
          :placeholder="placeHolderText"
          @change="updateData(inputValue)"
      />
    </template>
  </v-calandar-date-picker>
</template>
